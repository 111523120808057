import React from 'react';
import Chart from "react-apexcharts";

import './App.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: 'line',
          foreColor: '#eee',

        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        },
        stroke: {
          curve: 'smooth',
        }
      },
      theme: {
        mode: 'dark'
      },
      tooltip: {
        theme: 'dark'
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
      ]
    };
  }

  render() {
    const {options, series} = this.state;

    return (
        <div className="main">
          <div className="centered">
            <Chart
                options={options}
                series={series}
                width="500"
            />
          </div>
        </div>
    );
  }
}

export default App;
